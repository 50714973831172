import React, { useState, useEffect } from "react";
import Link from "next/link";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";
import Image from "next/image";
import parse from "html-react-parser";

//Component Imports
import Card, { ReactionIcons } from "components/global/Card";
import BookmarkButton from "../bookmarks/BookmarkButton";
import FollowButton from "components/article/FollowButton";
import ShareButton from "components/global/ShareButton";

// Interfaces
import {
    IPageApi,
    IfaceStravitoFiles,
    IfaceStravitoFile,
    IfaceCurrentUser,
    ifacePageComment, IfaceAllNewestResponse,
} from "helpers/interfaces";

import { useRecoilValue } from "recoil";
import { topicListState } from "state/atoms";

// Api
import {
    fetchData,
    setInnerHtml,
    apiCall,
    getParams,
    processBannerImage,
    fetchReviewComments,
    postNewReviewComment,
    deleteReviewComment,
    resolveReviewComment,
    fetchUserPreferences,
    updateUserPreferences,
    slugify,
    postReaction
} from "helpers/functions";

// Images for inline SVG
import IconViews from "images/icon-views-2.svg";
import Globe from "images/icon-globe.svg";
import Folder from "images/icon-folder.svg";
import IconThumbsUp from "images/icon-thumbsup.svg";
import Close from "images/ui-white-close.svg";
import Plus from "images/icon-plus.svg";
import IconArticle from "images/icon-article.svg";
import IconDownload from "images/icon-download.svg";
import IconFGD from "images/icon-fgd-tab.svg";
import IconBrief from "images/icon-brief.svg";
import IconCampaign from "images/icon-campaign.svg";

// Styles
import styles from "styles/pages/Home.module.scss";
import cardStyles from "styles/components/Card.module.scss";

type Props = {
    featured: any;
};

// Functions
function capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const FeaturedBanner = ({ featured }: Props) => {
    // Bookmarked State
    const [isBookmarked, setIsBookmarked] = useState(false);
    useEffect(() => {
        if (!isBookmarked && featured && featured.user_bookmarked) {
            setIsBookmarked(true);
        }
    }, []);

    const [liveReactions, setLiveReactions] = useState<any>({
        "thumbs_up": 0,
        "thumbs_down": 0,
        "shamelessly_steal": 0,
        "i_want_more": 0
    });

    const [userReaction, setUserReaction] = useState({
        thumbs_up: false,
        thumbs_down: false,
        shamelessly_steal: false,
        i_want_more: false,
    });

    async function sendReaction(
        reaction:
            | "thumbs_up"
            | "thumbs_down"
            | "shamelessly_steal"
            | "i_want_more"
    ) {
        const id = featured.article_id;
        const reactionResponse = await postReaction(id, reaction);
    }

    const reactthumbs_up = () => {
        // Clone OG Value
        let newUserReaction = { ...userReaction };
        let newLiveReactions = { ...liveReactions };

        // Logic for thumbs up
        if (userReaction.thumbs_up) {
            newUserReaction.thumbs_up = false;
            newLiveReactions.thumbs_up--;
        } else {
            newUserReaction.thumbs_up = true;
            newLiveReactions.thumbs_up++;
        }

        // Unshamelessly_steal thumbs down if it's clicked
        if (userReaction.thumbs_down && newUserReaction.thumbs_up) {
            newUserReaction.thumbs_down = false;
            newLiveReactions.thumbs_down--;
        }

        sendReaction("thumbs_up");

        // Make it so
        setUserReaction(newUserReaction);
        setLiveReactions(newLiveReactions);
    };

    //Follow Toggle
    const [showFollow, setShowFollow] = useState<boolean>(false);

    // Follow User Preferences
    const [userPreferences, setUserPreferences] = useState<any>({});
    const [activePrefCount, setActivePrefCount] = useState<number>(0);

    const getUserPrefs = async () => {
        const newestPostsApi: IfaceAllNewestResponse = await fetchUserPreferences();
        const prefData: any = newestPostsApi.res.data;
        if (userPreferences !== prefData) {
            setUserPreferences(prefData);
        }
    };

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getUserPrefs(); // get initial data
            document.body.addEventListener('click', (e) => {
                const container = document.getElementById('article-follow-container');
                if (container !== null && (container !== e.target && !container.contains(e.target as Node))) {
                    // updateActivePrefs(container);
                    setShowFollow(false);
                }
            }, true);
        }
        return () => {
            isMounted = false;
        };
    }, []);

    const updatePrefCount = () => {
        if (userPreferences) {
            let updatedCount = 0;
            for (let key in userPreferences) {
                if (userPreferences[key] && key === 'queries') {
                    updatedCount += userPreferences[key].length;
                }
            }
            if (activePrefCount !== updatedCount) {
                setActivePrefCount(updatedCount);
            }
        }
    }

    useEffect(() => {
        updatePrefCount();
    }, [userPreferences]);

    const [id, setId] = useState<any>(typeof featured.id === 'string' ? parseInt(featured.id.replace('article-', '')) : featured.id);

    console.log('featured', featured);

    const [bannerURL, setBannerURL] = useState<any>(featured.slug ? featured.slug : '');
    const [hideMeta, setHideMeta] = useState<boolean>(false);
    const [date, setDate] = useState<any>(null);
    const [bannerImage, setBannerImage] = useState<any>('/images/null-placeholder.png');
    const [categories, setCategories] = useState<any>(null);
    const [typeIcon, setTypeIcon] = useState<any>(<IconArticle />);
    const [typeLabel, setTypeLabel] = useState<any>('Article');
	const [publishedTopics, setPublishedTopics] = useState<any>([]);
	const availableTopics = useRecoilValue(topicListState);

	useEffect(() => {
		if (availableTopics.loaded && !availableTopics.error && availableTopics.res.data.length > 0) {
			setPublishedTopics(availableTopics.res.data.filter((topic: any) => !topic.slug.includes('/draft') && !topic.slug.includes('/archived')));
		  }
	  }, [availableTopics]);

    useEffect(() => {
        // set banner url
        if (featured.type) {
            if (featured.type === 'article') {
                setBannerURL('/articles/' + bannerURL);
            } else if (featured.type === 'campaign') {
                setBannerURL('/creative/' + bannerURL);
                setTypeLabel('Creative Campaign');
                setTypeIcon(<IconCampaign />);
            } else if (featured.type === 'brief') {
                setBannerURL('/brief/' + bannerURL);
                setTypeLabel('Brief');
                setTypeIcon(<IconBrief />);
            }
            if (featured.type === 'customCard') {
                setHideMeta(true);
            }
        }
        // set date
        if (featured.date_published) {
            setDate(dayjs(
                featured.date_published
            ).format("MMM DD, YYYY"));
        } else if (featured.publishedAt) {
            setDate(dayjs(
                featured.publishedAt
            ).format("MMM DD, YYYY"));
        }

        // set banner background image
        if (featured.banner) {
            setBannerImage(processBannerImage(featured.banner));
        } else if (featured.card_thumbnail) {
            setBannerImage(processBannerImage(featured.card_thumbnail));
        }

        // set categories

        if (featured.type === 'article' && featured.categories) {
            setCategories(featured.categories);
        } else if (featured.type === "customCard" && featured.categories) {
            let customCats: any = [];
            featured.categories.forEach((cat: any) => {
                const item = {
                    title: cat,
                    slug: slugify(cat)
                };
                customCats.push(item);
            })
            if (customCats.length > 0) {
                setCategories(customCats);
            }
        }
    }, [featured]);

	let finalTopics: any[] = [];
	if (categories) {
		categories.map((u: any) => {
			publishedTopics.filter((n: any) => {
				if (n.title === u.title) {
					finalTopics.push(n);
				};
			});
		});
	}

	return (
        <section id={'featuredBanner'} className={styles.featuredBanner}>
            <div className={styles.image}>
                <Image
                    unoptimized={true}
                    src={processBannerImage(bannerImage)}
                    alt={featured.title}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="bottom center"
                    priority={true}
                />
            </div>
            <div className={`wrapper ${styles.wrapper} ${styles.textContainer}`}>
                <div className={styles.topContent}>
                    {featured.type && featured.type !== 'customCard' && (
                        <p className={styles.objectType}>
                            {typeIcon}
                            <span>{typeLabel}</span>
                        </p>
                    )}
                    {featured.type && (featured.type === 'customCard' || featured.type === 'custom_card') && featured.cardType && (
                        <p className={`${styles.objectType} ${featured.cardType ? styles.fgdType : ''}`}>
                            {featured.card_type === 'Download' ? (
                                <IconDownload />
                            ) : (
                                <IconFGD />
                            )}
                            <span>{capitalizeFirstLetter(featured.cardType)}</span>
                        </p>
                    )}
                    <Link href={bannerURL}>
                        <a id={'featured-link'} className={styles.isCardTitle}>
                            <h1 className="large">
                                {featured.title}
                            </h1>
                        </a>
                    </Link>
					{featured.shortDescription && (
						<p className={styles.subtitle}>{parse(featured.shortDescription)}</p>
					)}
					{featured.type === "customCard" && featured.short_description && (
						<p className={styles.description}>{parse(featured.short_description)}</p>
					)}
                    <div
                        className={`${styles.meta} ${cardStyles.meta} meta-from-cards`}>
                        <div className={styles.metaData}>
                            {date && (
                                <p className={`${cardStyles.date} date`}>{date}</p>
                            )}
                            {!hideMeta && (
                                <>
                                    <p className={`views ${cardStyles.views} ${styles.views}`}>
                                        <IconViews />{" "}
                                        <span className="count">
                                            {featured.views | 0}
                                        </span>
                                    </p>
                                    {featured.market && featured.market !== '' && (
                                        <div className={`${cardStyles.countryContainer} countryContainer`}>
                                            <p className={`${cardStyles.country} ${styles.country} country`}>
                                                <Globe />
                                                {featured.market}
                                            </p>
                                            <div className={`${cardStyles.metaHover} metaHover`}>
                                                <p className={`${cardStyles.hoverLabel} hoverLabel`}>
                                                    <Globe />
                                                    <span>Market</span>
                                                </p>
                                                <ul>
                                                    <li key={`featured-market`}>{featured.market}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}
                            {finalTopics && finalTopics.length > 0 && (
                                <div className={`${cardStyles.categoryContainer} categoryContainer`}>
                                    <p className={`${cardStyles.category} category`}>
                                        <Folder />
                                        {finalTopics.map((u: any) => u.title).join(', ')}
                                    </p>
                                    <div className={`${cardStyles.metaHover} metaHover`}>
                                        <p className={`${cardStyles.hoverLabel} hoverLabel`}>
                                            <Folder />
                                            <span>{featured.type === "customCard" ? 'Category' : 'Topics'}</span>
                                        </p>
                                        <ul>
                                            {Array.isArray(finalTopics) ? finalTopics.map((topic: any, index: any) => {
                                                return (
                                                    <li key={`featured-topic-${index}`}>
                                                        {featured.type === "customCard" ? (
                                                            <p>{topic.title}</p>
                                                        ) : (
                                                            <a href={`/topics/${topic.slug}`}>{topic.title}</a>
                                                        )}
                                                    </li>
                                                )
                                            }) : (
                                                <li key={`featured-topic`}>
                                                    {featured.type === "customCard" ? (
                                                        <p>{featured.categories.title}</p>
                                                    ) : (
                                                        <a href={`/topics/${featured.categories.slug}`}>{featured.categories.title}</a>
                                                    )}
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            )}
                        </div>
                        {!hideMeta && (
                            <div className={`${cardStyles.bottomMeta} ${styles.bottomMeta} bottomMeta`}>
                                <div className={`thumbsUpItem`}>
                                    <button
                                        data-tip="Thumbs Up"
                                        data-place="bottom"
                                        data-effect="solid"
                                        className={`reactUp ${userReaction.thumbs_up
                                            ? "active reaction-selected"
                                            : "inactive reaction-unselected"
                                            } reaction-button reaction-thumbs-up reaction-thumbs-up-${featured.article_id}`}
                                        onClick={(e) => {
                                            reactthumbs_up();
                                        }}>
                                        <span className={`iconContainer ${userReaction.thumbs_up && 'active'}`}>
                                            <IconThumbsUp />
                                        </span>
                                        <p>{liveReactions.thumbs_up}</p>
                                        <span className={`reaction-overlay thumbs-up-reaction ${userReaction.thumbs_up ? 'reaction-selected' : 'reaction-unselected'}`}>Thumbs Up</span>
                                    </button>
                                    <ReactTooltip />
                                </div>
                                <div className={'bookmarkItem'}>
                                    <BookmarkButton
                                        article_id={id}
                                        article_slug={featured.slug}
                                        isBookmarked={isBookmarked}
                                        setIsBookmarked={setIsBookmarked}
                                        setToggleArticle={null}
                                        showLabel={true}
                                    />
                                </div>
                                {/*<span className="share"></span>*/}
                                <FollowButton page={{ res: { data: featured } }} />
                                <ShareButton
                                    slug={featured.slug ? featured.slug : featured.url}
                                    objectType={featured.type}
                                    pageTitle={featured.title}
                                    showLabel={true}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FeaturedBanner;